// Health.jsx

import React from 'react';
import styles from './Health.module.scss';
import img from './img/1.svg'

const Health = () => {
  return (
    <>
     <div className={styles['health-container']}>
      <h1>Что такое HEALTH+ ?</h1>
      <img src={img} alt="health" />
      <h3>
        HEALTH+ — это точная, анонимная и бесплатная проверка здоровья
        секс-работников.
      </h3>
      <p>
        У наших девушек — по результатам проверки своего здоровья у специальных
        и верифицированных нами врачей не было выявлено ни одного заболевания,
        передающегося половым путём.
      </p>
      <p>
        Стоит отметить, что отсутствие заболеваний не является поводом для
        незащищённого секса. 100%-ю гарантию отсутствия болезней не даст ни один
        врач или лаборант. Предохраняться необходимо всегда.
      </p>
      <p>
        Статус HEALTH+ даётся на 30 дней, в течение которых девушка должна
        повторно провериться у врача. Если в течение месяца девушка не посещала
        нашего врача, то статус HEALTH+ снимается и ее анкета удаляется с нашего
        агенства.
      </p>
    </div>



    
    </>
   



  );
};

export default Health;



